import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/vsDark'
import PropTypes from "prop-types";

function CodeBlock(props){

    const className = props.children.props.className || ''
    const matches = className.match(/language-(?<lang>.*)/)
    const code = props.children.props.children;

    return (
        <div>
            <Highlight {...defaultProps} theme={theme} code={code.trim()} language={
                matches && matches.groups && matches.groups.lang
                    ? matches.groups.lang
                    : ''}>
                {({className, style, tokens, getLineProps, getTokenProps}) => (
                    <pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
              <div key={i} {...getLineProps({line, key: i})}>
                  {line.map((token, key) => (
                      <span key={key} {...getTokenProps({token, key})} />
                  ))}
              </div>
          ))}
        </pre>
                )}
            </Highlight>
            <br/>
        </div>
    )
}

CodeBlock.propTypes = {
    children: PropTypes.node
};

export default CodeBlock;